import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../models/types';
import { Store } from '@ngrx/store';
import { Stalls, Stalls_AllInfo } from '../models/stalls.model';
import { EinStoreInfo } from '../models/selectStore.model';

@Injectable()
export class EinvoiceService {
    urlTPC: string;
    url: string;
    constructor(
        private http: HttpClient,
        private store: Store<Stalls[]>,
        @Inject('BASE_URL') baseUrl: string,
    ) {
        this.url = baseUrl;
    }   
 
    getStore_Info(maCH: string) {
      return this.http.post(`${this.url}api/Einvoice/getStoreInfo`, {MaCH: maCH}).toPromise()
        .then((result: ServerResponse) => {
            if (result.code = 1) {
                return result.data;
            }
        });
    }

    getEinvoice_Store(maCH: string) {
      return this.http.post(`${this.url}api/Einvoice/getEinvoices`, { MaCH: maCH }).toPromise()
        .then((result: ServerResponse) => {
          if (result.code = 1) {
            return result.data;
          }
        });
    }

  viewEinvoice(TransactionID: string) {
    return this.http.post(`${this.url}api/Einvoice/viewEinvoice`, { TransactionID: TransactionID }).toPromise()
      .then((result: ServerResponse) => {
        if (result.code = 1) {
          window.open(result.data, '_blank');
          return result.data;
        }
      });
  }

    setStore_Info(stInfo: EinStoreInfo) {
      return this.http.post(`${this.url}api/Einvoice/setStoreInfo`, {
        MaCH: stInfo.MaCH,
        MauSo: stInfo.MauSo,
        KyHieu: stInfo.KyHieu,
        MaNVTao: stInfo.MaNVTao,
        TenDonVi: stInfo.TenDonVi,
        MST: stInfo.MST,
        SDT: stInfo.SDT,
        DiaChi: stInfo.DiaChi,
        Email: stInfo.Email,
        Website:  stInfo.Website}).toPromise()
      .then((result: ServerResponse) => {
        if (result.code = 1) {
          return result.data;
        }
      });
  }
}
