import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SigninService } from '../../../services/signin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { resultMemoize } from '@ngrx/store';
import { Logo } from '../../../models/logo.model';
import { LogoService } from '../../../services/configuration/logo.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  [x: string]: any;
  signInForm: FormGroup;
  submitted = false;
  goterror = false;
  messageSuccess: any;
  messageError: any;
  uid: string;
  url: string
  logo;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private signinService: SigninService,
    private logoService: LogoService,
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
  ) {
    if (localStorage.getItem('_user')) {
      this.router.navigate(['/pages/iot-dashboard']);
    }

    this.url = baseUrl + 'assets/images/Logo/';
     this.logoService.getFileName()
       .then(result => {
         this.logo = result.lg_start;
      })
  }

  ngOnInit() {
    this.signInForm = this.fb.group({
      account: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  get f() { return this.signInForm.controls; }

  onSignIn() {
    this.submitted = true;
    this.goterror = false;
    if (this.signInForm.invalid) {
      return;
    }
    const {account, password} = this.signInForm.value;

    this.signinService.loginUser(account, password)
    .then(result => {
      this.messageSuccess = {
        status: 1,
        message:  'Đăng nhập thành công.',
      };
    })
    .catch(err => {
      this.goterror = true;
      this.messageError = {
        status: 1,
        message: err,
      };
    });

  }

  BackTOPOSClient() {
    this.http.get('manifest.json', { responseType: 'json' }).subscribe( async name => {
      const urlTPC: string = await name['config_topos'];
      window.location.href = urlTPC;
    });
  }


  BackToTPC() {
    this.http.get('manifest.json', { responseType: 'json' }).subscribe(async name => {
      const urlTPC: string = await name['config_topos'];
      this.deleteCookie('_acceptKey')
      window.location.href = urlTPC;
    });
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires = ' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }


  getCookie(cname) {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === '  ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  deleteCookie(cname) {
    var d = new Date();
    d.setTime(d.getTime() + (0 * 24 * 60 * 60 * 1000));
    var expires = "expires = " + d.toUTCString();
    document.cookie = cname + "=;" + expires + ";path=/";
  }
}
