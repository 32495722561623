
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../models/types';


@Injectable({
  providedIn: 'root',
})
export class SaleBackupService {
  url: string;
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
  ) {
    this.url = baseUrl;
    // console.log(this.url)
  }

  async getListFileSB_CH(storeid: string) {
   return this.http.get(this.url + 'api/SaleBackup/' + storeid)
     .toPromise()
     .then((result: ServerResponse) => {
       console.log(result);
       if ( result.code === 1) {
         return result.data;
       } else if (result.code === 2) {
         throw new Error (result.message);
       } else {
         throw new Error (result.message);
       }
     });
  }

  async ReupFileSB_CH(lstFile: string[]) {
    // const names = JSON.stringify(lstFile);
    return this.http.post(this.url + 'api/SaleBackup/ReuploadDataStore',{name: lstFile})
      .toPromise()
      .then((result: ServerResponse) => {
        console.log(result);
        if ( result.code === 1) {
          return result.data;
        } else if (result.code === 2) {
          throw new Error (result.message);
        } else {
          throw new Error (result.message);
        }
      });
   }

  // async export_dataItems(storeid: string, tillid: string, date: Date, type: string) {
  //  return await this.http.post(this.url + 'api/ExportFile/ExportTurnoverbyItems',
  //    { StoreID: storeid, TillID: tillid, Date: date, type: type })
  //    .toPromise()
  //    .then((result: ServerResponse) => {
  //      console.log(result);
  //      if ( result.code === 1) {
  //        return result.message;
  //      } else if (result.code === 2) {
  //        throw new Error (result.message);
  //      } else {
  //        throw new Error (result.message);
  //      }
  //    });
  // }

  // async export_dataPayment(storeid: string, tillid: string, date: Date, type: string) {
  //  return await this.http.post(this.url + 'api/ExportFile/ExportTurnoverbyPayment',
  //    { StoreID: storeid, TillID: tillid, Date: date, type: type })
  //    .toPromise()
  //    .then((result: ServerResponse) => {
  //      console.log(result);
  //      if ( result.code === 1) {
  //        return result.message;
  //      } else if (result.code === 2) {
  //        throw new Error (result.message);
  //      } else {
  //        throw new Error (result.message);
  //      }
  //    });
  // }

  async export(storeid: string, date: Date) {
    return await this.http.post(this.url + 'api/ExportFile/export',
      { StoreID: storeid, Date: date})
      .toPromise()
      .then((result: ServerResponse) => result);

  }

}
