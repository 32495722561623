import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Item } from '../../models/item.model';
import { Store } from '@ngrx/store';
import { ServerResponse } from '../../models/types';

@Injectable()
export class ItemService {
    url: string;
    urlTPC: string;
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string,
    ) {
        this.url = baseUrl;
        this.http.get('manifest.json', { responseType: 'json' }).subscribe( async name => {
            this.urlTPC = await name['config_topos'];
          });
    
    }
  
    getItem(data: Item) {
        return this.http.post(`${this.url}api/Item/SearchItem`, {
            MaHH: data.UPC,
            TenHH: data.TenHH,
            MaGoiNho: data.SKU
        }).toPromise()
            .then((result: ServerResponse) => {
                console.log(result[0]);
                if (result[0].code === 1) {
                    return result[0].data;
                } else {
                    throw new Error(result[0].message);
                }
            });
    }

    getImgProduct(value) {
        const img = value + '.jpg';
        return this.http.get(`${this.urlTPC}assets/images/Products/` + img, { responseType: 'text' })
            .toPromise()
            .then(data => {
                if (data.match('html')) {
                    return Promise.resolve(false);
                }
                return Promise.resolve(true);
            })
            .catch(err => {
                return Promise.resolve(false);
            });

    }

    uploadFile(formData: FormData) {
        return this.http.post(this.url + `api/Item/UploadFileImage`, formData , {
            reportProgress: true,
            })
        .toPromise()
        .then((result: ServerResponse) => {
            if (result.code === 1) {
                return true;
            } else {
                throw new Error(result.message);
            }
        });
    }
}
