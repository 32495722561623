import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SigninService } from '../../../services/signin.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticateService } from '../../../services/authenticate.service';

@Component({
  selector: 'app-check-uid',
  templateUrl: './check-uid.component.html',
  styleUrls: ['./check-uid.component.css'],
})
export class CheckUidComponent implements OnInit {
  uid: string;
  ivalidSignin = false;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private signinService: SigninService,
    private authenticationService: AuthenticateService,
  ) {
    //window.addEventListener('beforeunload', function (e){
     localStorage.removeItem('_user');
    //});
    this.route.params.subscribe(params => {
      this.uid = params['uid'];
      if (this.uid) {
        this.signinService.checkUID(this.uid).then(result => {
          if(result === true)
          {
          this.setCookie('_acceptKey', this.uid, 748250);
          this.ivalidSignin = result;
        }
        });
      } else {
        this.ivalidSignin = false;
        // this.http.get('manifest.json', { responseType: 'json' }).subscribe( async name => {
        //   const urlTPC: string = await name['config_topos'];
        //   window.location.href = urlTPC;
        // });
        const uid = this.getCookie('_acceptKey');
        this.authenticationService.redirectTPA(uid);
      }
    });
  }

  ngOnInit() {
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires = " + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    //console.log(document.cookie);
  }


  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == '  ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
}
